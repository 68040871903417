body{
  background-color: #262626;
  font-family: poppins;
}

.w-font{
  color: white;
}

.w-para{
  color: #CFCFCF;
  text-align: justify !important;
}
#metamask-card{
  color: white !important;
  margin: 0;   
  width: "40%"  
}
.main-nav{
  position: fixed;
  top: 0;
  background: #262626;
  padding: 0;
  z-index: 999;
}
.mo-primary{
  width: 100%;
  padding: 15px;
  font-size: 20px;
  border-color: #7D69F6;
  background: #7D69F6;
  letter-spacing: 1.5px;
}

.mo-subheading{
  letter-spacing: 1.5px;
  color: #7D69F6;
  padding-left: 16px;
  font-size: 20px;
  font-weight: 600;
}
.card-dark{
  background:black;
  width: 18rem;
  margin-left: 30px;
  margin-top: 30px;
  border-radius: 4%;
  box-shadow: 0px 20px 60px rgba(0,0,0, 0.5);
}

/* .card-img:hover{
  transition: 1s;
  transform: scale(1.1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
} */

.h-color{
  color: white !important;
}

.p-color{
  color: #CFCFCF !important;
}
.r-color{
  color: #FFCCBB;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-justify{
  text-align: justify;
}

.price{
  color: #7D69F6;
}

.main-price{
  color: #FFFFFF !important;
  font-family: "DM Sans", Sans-serif;
  font-size: 43px;
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: 2px;
}

.inline-display{
  display: inline;
}

.fr{
  float: right;
}

.fl{
  float: left;
}

.mo-search{
  border-radius: 30px;
  height: 32px;
  margin-left: 50px;
  background: #606060;
  border-color: #606064;
  color: white;
  margin-top: 5px;
}

.mo-search-form{
  width: 30%;
}

.nav-btn{
  border-radius: 10px;
  padding: 12px 20px;
  background:#7D69F6
}

.mo-nav{
  text-align: center;
  margin-left: 30%;
}

.mr-30{
  margin-right: 30px;
}
.active{
  color: #7D69F6 !important;
}
a:active{
  color: #7D69F6 !important;
}

.product-card{
  padding: 30px;
  background: #34363d;
  border-radius: 20px;
  max-height: 470px;
}

.sub-price{
  color: #7A8293 !important;
}

.action-button{
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #7D69F6;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #7D69F6;
  width: 100%;
  border-radius: 30px 30px 30px 30px;
  box-shadow: 0px 0px 0px 0px rgb(54 187 145 / 28%);
  padding: 15px 50px 15px 50px;
}

.nft-image{
  border-radius: 20px;
}

.marketplace-message{
  text-align: center;
  margin-top: 50px !important;
  background: #6c6c6c;
  width: 30%;
  margin: auto;
  padding: 20px;
  border-radius: 20px;
  color: rgb(255, 255, 255) !important;
}

.swal2-backdrop-show{
  background: rgb(255 255 255 / 0%);
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur( 3px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.swal2-styled.swal2-cancel{
  border-radius: 2rem;
}

/* .image-box {
  position: relative;
  overflow: hidden;
  max-width: 300px;
  z-index: 1;
}


.img-card {
  transition: .5s ease all; 
  max-width: 100%; 
  transition: .1s ease all;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: 10px;
  transform: scale(1);
  border-radius: 20px !important;
  padding: 5px;
  object-fit:cover;
}

.image-box:hover img {
  transform: scale(1.1);
  border-radius: 20px;
} */

.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;
  margin-top: 10px;
  border-radius: 10px;
  height: 200px;

}

.image-box img {
  max-width: 100%;
  transition: all 1s;
  display: block;
  width: 100%;
  transform: scale(1);
  height: 100%;
  object-fit: cover;
}

.image-box:hover img {
  transform: scale(1.2);
}

.logo{
  margin-top: 4px;
  width: 150px !important;
}

.logo-image{
  width: 100%;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(171, 171, 172, 0.675) !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:rgba(171, 171, 172, 0.675) !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color:rgba(171, 171, 172, 0.675) !important;
}

.product-description{
  max-height: 125px;
  overflow: auto;
}

.nft-form{
  background: #34363d;
  padding: 50px;
  border-radius: 20px;
}

.nft-form input{
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      border-color: black;
      color: #212529;
      background-color: #606060;
      background-clip: padding-box;
      appearance: none;
      border-radius: 10px;
      padding: 10px;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.nft-form label{
  color: #93a0ac;
}

.file-upload{
  width: 97%;
  margin-left: 1.5%;
}

.lazy-mint-checkbox{
  width: 4px !important; 
  display: inline-flex !important;
  margin-right: 10px !important;
}

.form-check-input:checked {
  background-color: #7D69F6;
  border-color: #7D69F6;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > input[type='file'] {
  display: none
}

.file > label {
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  border-color: black !important;
  align-items: center;
  width: 100%;
  background: #606060;
  color: black !important;
}

.file > label:hover {
  border-color: hsl(0, 0%, 21%);
}

.file > label:active {
  background-color: hsl(0, 0%, 96%);
}

.file > label > i {
  padding-right: 5px;
}

.file--upload > label {
  color: hsl(204, 86%, 53%);
  border-color: hsl(204, 86%, 53%);
}

.file--upload > label:hover {
  border-color: hsl(204, 86%, 53%);
  background-color: hsl(204, 86%, 96%);
}

.file--upload > label:active {
  background-color: hsl(204, 86%, 91%);
}

.dottet-loader{
  height: 40px;
  width: 40px;
  border-color: #5e35b1;
  border-style: solid solid dotted dotted;
  border-width: 5px;
  border-radius: 50%;
  animation: turn-left 2s infinite linear;
  -webkit-animation: turn-left 2s infinite linear;
}
/* second circle */
.dottet-loader::before{
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  border: 2px wheat;
  border-style: solid dotted;
  height: 20PX;
  width: 20PX;
  border-radius: 50%;
  position: absolute;
  animation: turn-right 1s infinite linear;
  -webkit-animation: turn-right 1s infinite linear;
}
@keyframes turn-left {
  from{ 
      transform:rotate(0) ;
       -webkit-transform:rotate(0) ; 
       -moz-transform:rotate(0) ; 
       -ms-transform:rotate(0) ; 
       -o-transform:rotate(0) ; 
  }
  to{
      transform: rotate(1turn);
      -webkit-transform: rotate(1turn);
      -moz-transform: rotate(1turn);
      -ms-transform: rotate(1turn);
      -o-transform: rotate(1turn);
  }
  
}
@keyframes turn-right {
  from{ 
      transform:rotate(0) ;
       -webkit-transform:rotate(0) ; 
       -moz-transform:rotate(0) ; 
       -ms-transform:rotate(0) ; 
       -o-transform:rotate(0) ; 
  }
  to{
      transform: rotate(-1turn);
      -webkit-transform: rotate(-1turn);
      -moz-transform: rotate(-1turn);
      -ms-transform: rotate(-1turn);
      -o-transform: rotate(-1turn);
}
  
}

.success-message{
color: #8ee78e;
text-align: center;
}

.card-heading svg{
display: block;
margin: auto;
margin-top: -30px;
margin-bottom: 25px;
}

.welcome-card{
  background: #34363d;
  padding: 50px;
  border-radius: 20px;
  color: white;
}

select {
-webkit-appearance:none;
-moz-appearance:none;
-ms-appearance:none;
appearance:none;
outline:0;
box-shadow:none;
border:0!important;
background: #5c6664;
background-image: none;
flex: 1;
padding: 0 .5em;
color:#fff;
cursor:pointer;
font-size: 1em;

}
select::-ms-expand {
display: none;
}
.select {
position: relative;
  display: flex;
  height: 38px;
  background: #5c6664;
  overflow: hidden;
  border-radius: 5px;
}
.select::after {
content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 1em;
  background: #2b2e2e;
  cursor: pointer;
  pointer-events: none;
  transition: .25s all ease;
}
.select:hover::after {
color: #23b499;
}

.card-section{
margin-top: 100px;
}

.NftImage{
height: 500px;

}

.NftImage img{
height: 100%;
object-fit: cover;
}

.profile-image{
  width: 40%;
  height: 100px;
  margin-top: 150px !important;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.profile-image img{
border-radius: 50%;
  object-fit: cover;
  height: 100%;
}

.p-wallet{
width: 24ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  margin: auto;
  color: #808080;
}

.profile-card .card-dark{
 margin-top: 123px;
  height: 550px;
  position: fixed;
}

.profile-nfts{
margin-top: 92px;
}

.profile-card .card-body{
/* background-image: url(http://localhost/nft-css/cardbg.png); */
  background-repeat: no-repeat;
  background-position: 0% 136%;
  background-size: initial;
  border-radius: 20px;
}


@media (min-width:992px) and (max-width: 1400px) {
.profile-nfts{
  margin-left: 35%;
}
}

@media (min-width:768px) and (max-width: 992px) {
.profile-nfts{
  margin-left: 50%;
}
}

@media (max-width:768px) {
.profile-card{
  width: 100%;
}

.profile-nfts .card-dark{
  width: 100% !important;
  margin-left: 20% !important;
}

.profile-card .card-dark{
  position: relative;
  width: 100%;
  margin-left: auto;
}

.profile-image{
  height: 150px;
}
}

@media (max-width:992px) {
.listNft {
  width: 100%;
  margin: auto;
}
}


/* width */
::-webkit-scrollbar {
width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey; 
border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: grey; 
border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #5e37ff ; 
}

.swal2-styled.swal2-confirm{
border-radius: 2rem !important;
}
.message-box {
  height: 200px;
  width: 380px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: 200px;
  color: #FFF;
  font-weight: 300;
}
.message-box h1 {
  font-size: 100px;
  line-height: 46px;
  margin-bottom: 40px;
}
.message-box p {
  font-size: 30px;
}
.buttons-con .action-link-wrap {
  margin-top: 40px;
}
.buttons-con .action-link-wrap a {
  background: #7D69F6;
  padding: 8px 25px;
  border-radius: 6px;
  color: #FFF;
  /* font-weight: bold; */
  font-size: 20px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px
}
.buttons-con .action-link-wrap a:hover {
  background: #0b5ed7;
  color: #fff;
}

#Polygon-0, #Polygon-1 , #Polygon-2 , #Polygon-3 , #Polygon-4 , #Polygon-4, #Polygon-5 {
  animation: float 1s infinite ease-in-out alternate;
}
#Polygon-0 {
  animation-delay: 1s;
  margin-left: 20%;
}
#Polygon-1 {
  margin-left: 2%;
  margin-top: 8%;
}
#Polygon-2 {
  animation-delay: .2s; 
  margin-left: 4%;
  margin-top: 5%;
}
#Polygon-3 {
  animation-delay: .4s; 
  margin-left: 23%;
  margin-top: -7%;
}
#Polygon-4 {
  animation-delay: .6s; 
  margin-left: 8%;
  margin-top: -3%;
}
#Polygon-5 {
  animation-delay: .8s; 
  margin-left: 30%;
  margin-top: -2%;
}

@keyframes float {
    100% {
    transform: translateY(20px);
  }
}
@media (max-width: 450px) {
  #images {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -190px;
  }
  .message-box {
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -190px;
    text-align: center;
  }
}

.multiLine {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Truncates line 3 */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.form-control1:disabled, .form-control1[readonly] {
  background-color: #525151;
  opacity: 1;
}

.nftDetails{
  display:flex;
  justify-content: space-between;
  -webkit-box-pack: justify;
}

#logo-text{
  margin:auto;
  object-fit: contain;
  display: block;
}

#logo-btn{
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  /* margin-top: 1rem; */
  margin-bottom: 0;
  border: 2px dashed #555555;
  /* margin-top: 0; */
  color: #6c757d;
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 400;
  height: 75px;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group label{
  color : #d4d4d4;
  font-size: large;
}

.create-nft-form {
  background-color: #2e2e2e !important;
  border: 1px solid rgb(85, 85, 85) !important;
  padding: 0.7rem 1rem !important;
  border-radius: 10px !important;
}

  
.btn-wrap {
  position: relative;
  background: #eee;
  width: 60px;
  height: 24px;
  border-radius: 12px;
  transition: background 0.4s ease;
}
.btn-wrap::before {
  content: "";
  position: absolute;
  inset-block: 0;
  left: 0;
  width: 30px;
  border-radius: 100vh;
  background: #888;
  transition: transform 0.4s ease;
}
.btn-wrap:has(input[type=checkbox]:checked) {
  background: #111111;
}
.btn-wrap:has(input[type=checkbox]:checked)::before {
  transform: translateX(30px);
}
.btn-wrap input[type=checkbox] {
  opacity: 0;
  position: absolute;
  inset: 0;
  cursor: pointer;
}

.sticky-top{
  top: 130px;
  position: sticky !important;
  z-index: 99;
}

.form-control1 {
  display: block;
  width: 100%;
  padding: 0.56rem 0.8rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #e7e7e7;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #D7D6DA;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem 0 0 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control1 {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.form-control1[type="file"] {
  overflow: hidden;
}

.form-control1[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control1:focus {
  color: rgb(214, 213, 217);;
  background-color: #262626 !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 89, 247, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(0, 89, 247, 0.25);
}

.form-control1::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control1::-webkit-input-placeholder {
  color: #4E5A85;
  opacity: 1;
}

.form-control1::-moz-placeholder {
  color: #4E5A85;
  opacity: 1;
}

.form-control1:-ms-input-placeholder {
  color: #4E5A85;
  opacity: 1;
}

.form-control1::-ms-input-placeholder {
  color: #4E5A85;
  opacity: 1;
}

.form-control1::placeholder {
  color: #4E5A85;
  opacity: 1;
}

.form-control1:disabled, .form-control1[readonly] {
  background-color: #232328;
  opacity: 1;
}

.form-control1::file-selector-button {
  padding: 0.56rem 0.8rem;
  margin: -0.56rem -0.8rem;
  -webkit-margin-end: 0.8rem;
  margin-inline-end: 0.8rem;
  color: #7E8BB6;
  background-color: #EBEBED;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control1::file-selector-button {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.form-control1:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dfdfe1;
}

.form-control1::-webkit-file-upload-button {
  padding: 0.56rem 0.8rem;
  margin: -0.56rem -0.8rem;
  -webkit-margin-end: 0.8rem;
  margin-inline-end: 0.8rem;
  color: #7E8BB6;
  background-color: #EBEBED;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control1::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control1:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dfdfe1;
}

.form-control1-plaintext {
  display: block;
  width: 100%;
  padding: 0.56rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #7E8BB6;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control1-plaintext.form-control1-sm, .form-control1-plaintext.form-control1-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control1-sm {
  min-height: calc(1.5em + 0.2rem + 2px);
  padding: 0.1rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 1rem;
}

.form-control1-sm::file-selector-button {
  padding: 0.1rem 0.5rem;
  margin: -0.1rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control1-sm::-webkit-file-upload-button {
  padding: 0.1rem 0.5rem;
  margin: -0.1rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control1-lg {
  min-height: calc(1.5em + 2rem + 2px);
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border-radius: 1.5rem;
}

.form-control1-lg::file-selector-button {
  padding: 1rem 1.5rem;
  margin: -1rem -1.5rem;
  -webkit-margin-end: 1.5rem;
  margin-inline-end: 1.5rem;
}

.form-control1-lg::-webkit-file-upload-button {
  padding: 1rem 1.5rem;
  margin: -1rem -1.5rem;
  -webkit-margin-end: 1.5rem;
  margin-inline-end: 1.5rem;
}

textarea.form-control1 {
  min-height: calc(1.5em + 1.12rem + 2px);
}

textarea.form-control1-sm {
  min-height: calc(1.5em + 0.2rem + 2px);
}

textarea.form-control1-lg {
  min-height: calc(1.5em + 2rem + 2px);
}

.form-control1-color {
  max-width: 3rem;
  height: auto;
  padding: 0.56rem;
}

.form-control1-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control1-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control1-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.nft-description{
  overflow: auto;
  padding: 30px;
  max-height: 200px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 90%;
    /* margin-top:2.5rem !important ; */
  }
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .image-class{
   width: 220px;
   height: 220px;
  }
}

.p-color{
  color: #8f8f8f;
}
.hex-container {
  font-family: monospace; /* Use a monospaced font for better alignment */
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) for truncated text */
  max-width: 150px; /* Adjust the maximum width as needed */
}

/* Style the hexadecimal number text */
.hex-number {
  font-size: 16px;
  color: #666;
}

.royalty-percentage{
  background: #7a66ed;
  padding: 5px 10px;
  margin: 0;
  border-radius: 17px;
}

.list-price-input {
  border: 1px solid black;
  color: rgb(214, 213, 217);
  background-color: #1f1d1d;
  padding: 10px 20px;
}

.list-price-input:focus {
  color: #d1d1d1;
  background-color: #141414 !important;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.copy {
  position: relative;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  user-select: none;
}
